$headerHeight: 55px;
$nonProdBannerHeight: 39px;
$largeHeaderHeight: 80px;

:root {
  // https://stackoverflow.com/questions/50202991/unable-to-set-scss-variable-to-css-variable
  --xpt-header-height: #{$headerHeight};
}

nbs-header {
  font-family: 'Roboto-Regular', 'Roboto';
  width: 100%;
  z-index: 501;

  ul,
  li {
    padding: 0;
    margin: 0;
  }

  .xpt-header-non-production-banner-container {
    background: linear-gradient(-45deg, #b32c03, #a3003f, #005979, #006b52);
    background-size: 200% 200% !important;
    animation: nonProdBanner 30s ease-in-out infinite !important;
    color: white;
    display: none;
    height: $nonProdBannerHeight;
    position: fixed;
    top: 0;
    width: 100%;
    line-height: 20px;
  }

  .xpt-header-non-production-banner-message {
    border: white 2px dashed;
    margin: 4px;
    padding: 4px;
    text-align: center;
    white-space: nowrap;
    width: 100%;
  }

  .xpt-header-container {
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    height: $headerHeight;
    position: fixed;
    top: 0;
    width: 100%;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }

  .xpt-header-logo-container {
    margin-left: 16px;
    padding: 0px 3px;
  }

  .xpt-header-logo img {
    max-height: 45px;
    max-width: 300px;
    vertical-align: middle;
  }

  .xpt-header-logo.xpt-svg-logo img {
    min-height: 45px;
  }
  .xpt-header-container.large .xpt-header-logo.xpt-svg-logo img {
    min-height: 70px;
  }

  .xpt-header-title {
    font-size: x-large;

    &.xpt-long-title {
      font-size: large;
    }

    div {
      display: table-cell;
    }

    div div {
      display: block;
      max-height: $headerHeight;
      overflow: hidden;
    }
  }

  .xpt-header-margin {
    margin-top: var(--xpt-header-height);
  }

  .xpt-header-menu-wrapper {
    flex-grow: 1;
    padding-top: 3px;
    white-space: nowrap;
  }

  .xpt-header-menu-title{
    font-size: 14px;
    font-weight: 500;
    color:rgba(0, 0, 0, 0.54)
  }

  .xpt-header-menu-text {
    [role="menu"], [role="list"] {
      .xpt-list-link {
        display: block;
        text-align: left;
      }
    }
  }

  .xpt-header-links-container {
    display: block;

    .xpt-main-nav-header {
      font-size: 14px;
      list-style: none;
      text-align: right;

      > .xpt-header-link {
        display: inline-block;

        >button {
          appearance: none;
          color: inherit;
          font-style: inherit;
          font-variant: inherit;
          font-stretch: inherit;
          font-size: inherit;
          font-family: inherit;
          font-optical-sizing: inherit;
          font-kerning: inherit;
          font-feature-settings: inherit;
          font-variation-settings: inherit;
          text-rendering: auto;
          letter-spacing: normal;
          word-spacing: normal;
          line-height: normal;
          text-transform: none;
          text-indent: 0px;
          text-shadow: none;
          text-align: left;
          align-items: flex-start;
          cursor: pointer;
          background-color: transparent;
          margin: 0em;
          padding: 0;
          border: none;
        }
      }
    }
  }

  .xpt-header-mobileLinks-container {
    display: none;
    text-align: right;
  }

  .xpt-header-menu-container {
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    left: 400px;
    position: absolute;
    text-align: left;
    top: 30px;

    .xpt-header-menu-scrollable-container {
      padding: 0px 15px 15px;
      overflow-y: auto;
      max-height: calc(100vh - var(--xpt-header-height) - 15px);
    }

    .xpt-arrow-up {
      transform: translateX(-15px);
    }

    .xpt-header-menu-divider {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .xpt-arrow-up {
    border-bottom: 8px solid #FFFFFF;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    float: right;
    height: 0;
    margin-top: -8px;
    width: 0;
  }

  .xpt-header-link {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-right: 16px;
  }

  .xpt-header-menu-link {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding-top: 10px;

    >button {
      align-items: flex-start;
      appearance: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-family: inherit;
      font-feature-settings: inherit;
      font-kerning: inherit;
      font-optical-sizing: inherit;
      font-size: inherit;
      font-stretch: inherit;
      font-variant: inherit;
      font-variation-settings: inherit;
      letter-spacing: normal;
      line-height: normal;
      margin: 0em;
      padding: 0;
      text-align: left;
      text-indent: 0px;
      text-rendering: auto;
      text-shadow: none;
      text-transform: none;
      word-spacing: normal;
    }
  }

  .xpt-header-menu-text {
    color: #333333;
    padding-top: 10px;

    &.xpt-disabled {
      color: rgba(0, 0, 0, .26);
    }
  }

  .xpt-header-menu-divider {
    background-color: #cccccc;
    border: 0;
    height: 1px;
    margin-bottom: 5px;
    margin-left: -7px;
    margin-right: -7px;
    margin-top: 15px;
  }

  .xpt-header-menu-section-heading {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
  }

  .xpt-header-container {

    a:link,
    a:visited,
    button:visited,
    a:active,
    button:active {
      text-decoration: none;
    }

    a:hover,
    button:hover {
      text-decoration: underline;
    }

    .xpt-header-menu-link a:link,
    .xpt-header-menu-link a:visited,
    .xpt-header-menu-link button:visited,
    .xpt-header-menu-link a:active,
    .xpt-header-menu-link button:active {
      color: #2B2B2B;
    }

    .xpt-header-menu-link a:hover,
    .xpt-header-menu-link button:hover {
      color: #2B2B2B;
    }
  }

  .xpt-header-menu-button {
    display: none;

    .material-icons {
      vertical-align: middle;
    }
  }

  .xpt-header-link .material-icons {
    margin-right: 5px;
    margin-top: -3px;
    vertical-align: middle;
  }

  .xpt-header-menu-text, .xpt-header-menu-link {
    .material-icons {
      margin-right: 10px;
    }
  }

  .xpt-header-menu-text, .xpt-header-menu-link {
    .open-in-new-tab-icon {
      margin-left: 5px;
      font-size: 18px;
      color: rgba(0,0,0,.55);
    }
  }

  .xpt-header-container,
  .xpt-header-container.large img,
  .xpt-header-container img,
  .xpt-header-container li{
    transition: .7s all;
    -moz-transition: .7s all;
    -webkit-transition: .7s all;
    -o-transition: .7s all;
  }
  .xpt-header-container.large {
    height: #{$largeHeaderHeight};
    border-bottom-style: solid;
    border-bottom-width: 4px;
  }
  .xpt-header-container.large img {
    max-height: 70px;
    max-width: 770px;
  }
  .xpt-header-container.large li {
    margin-top: 52px;
  }

  @media screen and (max-width: 768px) {

    .xpt-header-container,
    .xpt-header-container.large img,
    .xpt-header-container img,
    .xpt-header-container li{
      transition: none;
      -moz-transition: none;
      -webkit-transition: none;
      -o-transition: none;
    }
    .xpt-header-container.large {
      height: #{$headerHeight};
      border-bottom-style: solid;
      border-bottom-width: 2px;
    }
    .xpt-header-container.large img {
      max-height: 45px;
    }
    .xpt-header-container.large .xpt-header-logo.xpt-svg-logo img {
      min-height: 45px;
    }
    .xpt-header-container.large li {
      margin-top: 52px;
    }
    .xpt-header-mobileLinks-container {
      display: block;
    }

    .xpt-header-links-container {
      display: none;
    }

    .xpt-header-menu-button,
    .xpt-header-link button {
      background-color: inherit;
      border: none;
      cursor: pointer;
    }

    .xpt-header-menu-button {
      margin-left: 16px;
    }

    .xpt-header-link button .material-icons {
      margin-right: 0;
      margin-top: 0;
    }
  }

  @media screen and (min-width: 1280px) {
    .xpt-header-title.xpt-long-title {
      font-size: x-large;
    }
  }

  @media screen and (max-width: 480px) {
    .xpt-header-title {
      font-size: large;

      &.xpt-short-title {
        font-size: x-large;
      }

      &.xpt-long-title {
        font-size: medium;
      }
    }
  }

  @media screen and (max-width: 360px) {
    .xpt-header-title {
      font-size: medium;

      &.xpt-short-title {
        font-size: x-large;
      }

      &.xpt-long-title {
        font-size: small;
      }
    }
  }
}

body.xpt-is-non-production {
  // https://stackoverflow.com/questions/50202991/unable-to-set-scss-variable-to-css-variable
  --xpt-header-height: #{$headerHeight + $nonProdBannerHeight};

  .xpt-header-non-production-banner-container {
    display: flex;
    flex-direction: row;
  }

  .xpt-header-container {
    top: $nonProdBannerHeight;
  }
}

@media screen and (max-width: 768px) {
  body.xpt-menu-visible .xpt-header-menu-button {
    display: block;
  }
}

@keyframes nonProdBanner {
  0% {
    background-position:0% 50%;
  }
  50% {
    background-position:100% 50%;
  }
  100% {
    background-position:0% 50%;
  }
}
