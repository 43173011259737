nbs-menu {
  .xpt-menu-link-label-badge {
    display: inline-block;

    &.xpt-menu-link-label-badge-hidden {
      display: none;
    }

    >.xpt-menu-link-label-badge-value {
      padding: 0px 3px;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      background-color: #094A64;
      border-radius: 10px;
      margin-left: 10px;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      min-height: 7px;

      &.xpt-menu-link-label-badge-value-error {
        background-color: #A94442;
      }
    }

    >.xpt-menu-link-label-badge-value-description {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;

    }
  }
}