.page-content {
  margin-left: 0;
  padding: 30px 25px 25px;
}

body.xpt-is-non-production .page-content{
  top: 55+39px;
}

body .page-content .page-content-body {
  margin-left: auto;
  margin-right: auto;
  max-width: 1368px;
}

body.xpt-full-width .page-content .page-content-body {
  max-width: none;
}

@media screen and (min-width: 768px) {
  body.xpt-menu-visible .page-content {
    margin-left: 256px; // .page-content and nbs-menu width are the same, keep them in sync!
  }
}

@media screen and (max-width: 768px) {
  body.xpt-menu-visible .page-content {
    margin-left: 0;
    padding: 0px 8px;
  }
}

@media screen and (max-width: 520px) {
  body.xpt-menu-visible .page-content {
    padding: 0;
  }
}
