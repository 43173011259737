nbs-menu {

  *,
  ::after,
  ::before {
    box-sizing: content-box;
  }

  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  position: fixed;
  top: 120px;
  z-index: 500;

  >nav {
    display: none;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 4px;
    width: 256px; // .page-content and nbs-menu width are the same, keep them in sync!

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin: 0;
      padding: 0;
    }

    a:active,
    button:active,
    a:link,
    a:visited,
    a,
    button {
      color: rgba(0, 0, 0, 0.87);
      display: block;
      text-decoration: none;
    }

    .xpt-menu-link-item {

      >a:focus,
      >button:focus,
      >a:hover,
      >button:hover {
        background-color: rgba(0, 0, 0, 0.07);
        color: rgba(0, 0, 0, 0.87);
      }

      &.xpt-disabled>a,
      &.xpt-disabled>button {
        color: rgba(0, 0, 0, .26);
        background-color: unset;
      }
    }

    i.material-icons,
    svg.menu-icon {
      max-height: 24px;
      max-width: 24px;
      vertical-align: middle;
    }

    >ul {
      margin-bottom: 8px;

      // defaults for all link items
      li.xpt-expander-menu-item,
      li.xpt-menu-link-item {
        >button {
          appearance: none;
          font-style: inherit;
          font-variant: inherit;
          font-stretch: inherit;
          font-size: inherit;
          font-family: inherit;
          font-optical-sizing: inherit;
          font-kerning: inherit;
          font-feature-settings: inherit;
          font-variation-settings: inherit;
          text-rendering: auto;
          letter-spacing: normal;
          word-spacing: normal;
          line-height: normal;
          text-transform: none;
          text-indent: 0px;
          text-shadow: none;
          text-align: left;
          align-items: flex-start;
          cursor: pointer;
          background-color: transparent;
          margin: 0em;
          padding: 0;
          border: none;
        }

        >a,
        >button {
          border-radius: 5px;
          height: fit-content;
          margin-left: 8px;
          margin-right: 8px;
          overflow: hidden;
          padding-left: 8px;
          padding-right: 8px;
          width: 220px;

          >div {
            display: inline-block;

            &.xpt-menu-link-icon {
              margin-right: 24px;
              width: 24px;
            }

            &.xpt-menu-link-label {
              height: 40px;
              line-height: 40px;
              width: 172px;

              span:not(.xpt-menu-link-label-badge-value) {
                display: inline-block;
                line-height: 20px;
                max-height: 40px;
                vertical-align: middle;
              }
            }
          }
        }
      }

      >li.xpt-menu-link-item,
      >li.xpt-sub-menu-item>ul>li.xpt-menu-link-item {
        margin-bottom: 4px;
        margin-top: 4px;
      }

      // group (only at top level)
      >li.xpt-sub-menu-item {
        margin-top: 8px;

        >div {
          // heading
          color: rgba(0, 0, 0, 0.54);
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          margin-left: 16px;
          margin-bottom: 8px;
          padding-top: 16px;
        }

        >ul {
          // menu items
          border-bottom: 1px solid #D7D7D7;

          >li.xpt-expander-menu-item {
            >a,
            >button {
              >div {
                &.xpt-menu-link-label {
                  width: 10.5em;
                }
              }
            }
          }
        }
      }

      li.xpt-expander-menu-item.xpt-has-active-child {
        >a,
        >button {
          font-weight: bold;
        }
      }

      li.xpt-expander-menu-item {

        >a:focus,
        >button:focus,
        >a:hover,
        >button:hover {
          background-color: rgba(0, 0, 0, 0.07);
          color: rgba(0, 0, 0, 0.87);
        }
      }

      li.xpt-expander-menu-item.xpt-collapsed>ul {
        display: none;
      }

      >li.xpt-expander-menu-item {
        // top level expander and defaults for sub-expanders
        margin-top: 8px;

        >a:focus,
        >button:focus,
        >a:hover,
        >button:hover {
          background-color: rgba(0, 0, 0, 0.07);
          color: rgba(0, 0, 0, 0.87);
        }

        &.xpt-disabled>a,
        &.xpt-disabled>button {
          background-color: unset;
          color: rgba(0, 0, 0, .26);
        }

        >a,
        >button {
          padding-right: 0;
          width: 228px;

          >div {
            &.xpt-menu-link-label {
              width: 156px;
            }
          }
        }

        >ul>li.xpt-menu-link-item {
          margin-bottom: 4px;
          margin-top: 4px;
        }

        >ul>li.xpt-expander-menu-item {
          // level 2
          margin-bottom: 4px;
          margin-top: 4px;

          &.xpt-disabled>a,
          &.xpt-disabled>button {
            background-color: unset;
            color: rgba(0, 0, 0, .26);
          }

          >a,
          >button {
            margin-left: 8px;
            width: 220px;

            >div.xpt-menu-link-icon {
              margin-left: 40px;
              margin-right: 4px;
            }

            >div.xpt-menu-link-label {
              width: 152px;
            }
          }

          >ul>li.xpt-menu-link-item {
            // level 3
            margin-bottom: 4px;
            margin-top: 4px;

            >a,
            >button {
              background-color: unset;
              margin-left: 8px;
              width: 228px;
              padding-right: 0;

              &:focus,
              &:hover {
                font-weight: bold;
                height: fit-content;
              }

              >div.xpt-menu-link-icon {
                border-right: 2px solid rgba(0, 0, 0, 0.07);
                margin-left: 26px;
                margin-right: 12px;

                span {
                  display: inline-block;
                  line-height: 40px;
                  max-height: 40px;
                  vertical-align: middle;
                }
              }

              >div.xpt-menu-link-label {
                border-radius: 5px;
                height: fit-content;
                line-height: 40px;
                padding-left: 12px;
                padding-right: 8px;
                width: 144px;

                &.xpt-disabled {
                  background-color: unset;
                  color: rgba(0, 0, 0, .26);
                }

                span {
                  display: inline-block;
                  line-height: 20px;
                  max-height: fit-content;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }

    .xpt-active-menu-item>a,
    .xpt-active-menu-item>button {
      font-weight: bold;
    }
  }
}

nbs-menu{
  transition: .7s all;
  -moz-transition: .7s all;
  -webkit-transition: .7s all;
  -o-transition: .7s all;
}

body.xpt-is-non-production nbs-menu {
  top: 57+39px;
}

body .xpt-is-non-production #uxlMenuMobile{
  top: 57+39px !important;
}

#uxlMenuMobile{
  top: 57px !important;
}


@media screen and (min-width: 768px) {
  body.xpt-menu-visible nbs-menu nav {
    display: block;
  }
}

@media screen and (max-width: 768px) {

  body.xpt-is-non-production nbs-menu {
    top: 55+39px !important;
  }

  nbs-menu {
    top: 55px !important;
  }

  body.xpt-menu-visible nbs-menu nav {
    display: block;

    &.xpt-mobile-collapsed {
      display: none;
    }

    &.xpt-flyout-open {
      width: 56px;
    }
  }
}

.open-in-new-tab-icon > i.material-icons {
  color: rgba(0,0,0,.55);
  font-size: 18px;
  margin: 0 0 0 7px;
}
