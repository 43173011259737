.xpt-flyout-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 399;
  width: 100%;
  height: 100%;
}

nbs-menu {
  div.xpt-flyout-menu-item-container {
    display: grid !important;
    height: 40px;
    grid-template-columns: 48px 1fr 18px;
    align-items: center;
    line-height: 20px;
    cursor: pointer;
  }

  .xpt-flyout-label {
    max-height: 40px;
    display: flex;
    align-items: flex-start;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .xpt-flyout-arrow {
    color: rgba(0, 0, 0, 0.55);
    cursor: pointer;

    i {
      font-size: 18px;
      vertical-align: middle;
    }
  }

  xpt-flyout-menu {
    background-color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 400;
    height: 100%;
    width: 0px;
    max-width: 80vw;
    left: 256px;
    line-height: 22px;
    position: fixed;
    top: 55px;
    z-index: 500;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    transition: width ease 0.4s;

    &.xpt-flyout-open-width {
      width: 350px;
    }

    @media screen and (max-width: 768px) {
      left: 56px;
      top: 55px !important;
    }

    .xpt-flyout-container {
      margin: 15px 0 15px 15px;
      padding-right: 15px;
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-width: none;

      &:hover {
        scrollbar-width: thin;
      }

      &:hover::-webkit-scrollbar,
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 4px !important;
      }

      &:hover::-webkit-scrollbar-track,
      &::-webkit-scrollbar-track {
        background-color: transparent;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }

      &:hover::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      &:hover::-webkit-scrollbar {
        background-color: transparent;
        width: 6px !important;
      }

      &:hover::-webkit-scrollbar-track {
        background-color: transparent !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: #cccccc;
      }

      nav {
        margin-top: 15px;
      }
    }

    .xpt-flyout-header {
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .xpt-flyout-close-button {
      background-color: inherit;
      border: none;
      cursor: pointer;
      margin: 2px 0 0 0;
      padding: 0;
    }

    .xpt-flyout-search-input {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #C2C2C2;
      box-shadow: 1px 1px 4px #EBEBEB;
      border-radius: 3px;
      padding: 7px;
      outline: none;
      margin-top: 10px;
      font-size: 14px;
    }

    .xpt-flyout-search-input:focus {
      border: 1px solid #808080;
    }

    .xpt-empty-search-msg {
      margin-top: 10px;
      text-align: center;
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    ul {
      margin-left: 20px;
    }

    ul:first-child {
      margin-left: 0;
    }

    .xpt-flyout-tree-item {
      .xpt-flyout-selected {
        font-weight: 500;
        text-decoration: underline;
      }

      .xpt-flyout-disabled a,
      .xpt-flyout-disabled button {
        color: rgba(0, 0, 0, .26);
      }

      .xpt-flyout-menu-item-wrapper {
        border-radius: 5px;
        padding: 5px 0px 5px 10px;
        margin-right: 5px;

        >button {
          align-items: flex-start;
          appearance: none;
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-feature-settings: inherit;
          font-kerning: inherit;
          font-optical-sizing: inherit;
          font-size: inherit;
          font-stretch: inherit;
          font-style: inherit;
          font-variant: inherit;
          font-variation-settings: inherit;
          letter-spacing: inherit;
          line-height: inherit;
          margin: 0em;
          padding: 0;
          text-align: left;
          text-indent: 0px;
          text-rendering: auto;
          text-shadow: none;
          text-transform: none;
          word-spacing: normal;
        }
      }

      .xpt-flyout-menu-item-wrapper:not(.xpt-flyout-disabled):not(.xpt-flyout-selected):hover {
        background: rgba(0, 0, 0, 0.07);
      }
    }

    .xpt-flyout-menu-item {
      margin-bottom: 5px;
      display: grid;
      grid-template-columns: 1fr 25px;
      align-items: center;

      .xpt-flyout-menu-item-expander {
        display: inline-flex;
        border-left: 1px solid #BDBDBD;
        height: 100%;
        align-items: center;
        justify-content: start;
        padding-left: 5px;
      }

      .xpt-flyout-node-expanded {
        transform: rotate(90deg);
      }
    }

    .xpt-flyout-menu-item-expander
    >button {
      align-items: flex-start;
      appearance: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-family: inherit;
      font-feature-settings: inherit;
      font-kerning: inherit;
      font-optical-sizing: inherit;
      font-size: inherit;
      font-stretch: inherit;
      font-style: inherit;
      font-variant: inherit;
      font-variation-settings: inherit;
      letter-spacing: normal;
      line-height: normal;
      margin: 0em;
      padding: 0;
      text-align: left;
      text-indent: 0px;
      text-rendering: auto;
      text-shadow: none;
      text-transform: none;
      word-spacing: normal;
    }

    a:active,
    button:active,
    a:link,
    a:visited,
    button:visited,
    a,
    button {
      color: rgba(0, 0, 0, 0.87);
      display: block;
      text-decoration: none;
    }
  }
  xpt-flyout-menu{
    transition: .7s all;
    -moz-transition: .7s all;
    -webkit-transition: .7s all;
    -o-transition: .7s all;
  }
}

body.xpt-is-non-production nbs-menu xpt-flyout-menu {
  top: 55+39px;
  @media screen and (max-width: 768px) {
    left: 56px;
    top: 55+39px !important;
  }
}

.no-clickable{
  pointer-events: none;
}
