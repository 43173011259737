html body {
  background-color: #F8F8F8 !important;
  margin: 0;
  padding: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

nbs-header,
nbs-menu,
nbs-footer {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 16px;
}

.xpt-hidden {
  visibility: hidden;
}

.xpt-display-none {
  display: none;
}

.xpt-scrollable {
  scrollbar-width: none;

  &:hover {
    scrollbar-width: thin;
  }

  &:hover::-webkit-scrollbar,
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px !important;
  }

  &:hover::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  &:hover::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar {
    background-color: transparent;
    width: 6px !important;
  }

  &:hover::-webkit-scrollbar-track {
    background-color: transparent !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #cccccc;
  }
}
