nbs-footer {
  flex-grow: 1;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: #333333;
  font-family: 'Roboto-Regular', 'Roboto';

  .xpt-footer-container {
    padding-top: 15px;
    padding-bottom: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(204, 204, 204);
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;

    p.xpt-footer-text-line {
      padding-right: 25px;
      text-align: right;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      margin: 0px;
    }

    a,
    button,
    a:link,
    a:visited,
    button:visited,
    a:active,
    button:active {
      color: #3369B9;
      text-decoration: none;
    }

    a:hover,
    button:hover,
    a:focus,
    button:focus {
      color: #1D509D;
      text-decoration: underline;
    }

    ul {
      font-size: 14px;
      text-align: right;
      padding-left: 0;
      margin: 0;
      padding: 0;
      padding-right: 25px;
      padding-bottom: 10px;
      list-style: none;

      li {
        vertical-align: middle;
        display: inline-block;

        >button {
          align-items: flex-start;
          appearance: none;
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-family: inherit;
          font-feature-settings: inherit;
          font-kerning: inherit;
          font-optical-sizing: inherit;
          font-size: inherit;
          font-stretch: inherit;
          font-style: inherit;
          font-variant: inherit;
          font-variation-settings: inherit;
          letter-spacing: normal;
          line-height: normal;
          margin: 0em;
          padding: 0;
          text-align: left;
          text-indent: 0px;
          text-rendering: auto;
          text-shadow: none;
          text-transform: none;
          word-spacing: normal;
        }
      }

      li i {
        font-size: 18px;
        margin-right: 3px;
        vertical-align: middle;
        margin-top: -3px;
      }

      li .open-in-new-tab-icon {
        font-size: 16px;
      }

      li:after {
        content: "|";
        padding: 0 0.5rem;
      }

      li:last-child:after {
        content: '';
        padding: 0;
      }
    }

    .xpt-footer-links {
      .open-in-new-tab-icon {
        margin-left: 2px;
      }
    }
  }
}